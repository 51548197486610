
import  {defineComponent} from 'vue';
import { /* AboController, UserController, */ PaymentController } from '@/controller';
import { Button } from '@/ui/';
import { PageHeader } from '@/components';

export default defineComponent({
    name: "Payment",
    components: { Button, PageHeader },
    data(){
        return{
            checkoutId: '',
            resourcePath: '',
            resultCode: '',
            resultDescription: '',
            timestamp: '',
            paymentSuccessfully: false,
            paymentPending: false,
            icons: {
                loader: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>',
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },       
            checkStatusInProgress: false,
            showConfirmAutoPayment: false
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        if (this.$route.query.id) {
            const id : any = this.$route.query.id
            this.checkoutId = id;
            PaymentController.checkoutId = id
        }
        if (this.$route.query.resourcePath) {
            const path : any = this.$route.query.resourcePath
            this.resourcePath = path
            PaymentController.resourcePath = path
        }
        this.checkPaymentStatus();
    },
    methods: {
        async checkPaymentStatus() {
            if (this.checkoutId && this.resourcePath) {
                this.checkStatusInProgress = true;
                const res : any = await PaymentController.checkPaymentStatus({ checkoutId: this.checkoutId, resourcePath: this.resourcePath });
                console.log("Payment:checkPaymentStatus res ", res)
                this.paymentSuccessfully = PaymentController.payment.successfully;
                this.checkStatusInProgress = false;
                //if(this.paymentSuccessfully){ //show status also on error
                if (!PaymentController.payment.pending) {
                    this.resultCode = PaymentController.payment.statusResult.result.code
                    this.resultDescription = PaymentController.payment.statusResult.result.description
                    this.timestamp = PaymentController.payment.statusResult.timestamp
                    //this.showConfirmAutoPayment= true
                } else if (PaymentController.payment.pending) {
                    this.$router.push('/paymentstatus')
                }
            } else {
                console.log("Payment:checkPaymentStatus no uri params");
            }
        },
        handleBackToAccount() {
            this.$router.push('/account');
        }
    }  
});
